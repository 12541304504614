import React from 'react'
import './home-faq.css'
import Accordion from 'react-bootstrap/Accordion';

const Faq = () => {
  return (
    <>
      <section className='homefaqsec'>
        <div className='container'>


          <div className='row'>
            <div className='col-lg-7 '>
              <div className='faqtitlediv'>
                <h2 className='title  mb-4'>FAQ</h2>
              </div>
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>What is the Reximeter?</Accordion.Header>
                  <Accordion.Body>
                    <p className='accord-text'>The Reximeter is our unique fare calculation system that starts automatically when your ride begins. It calculates your fare based on the distance traveled, with real-time updates on the app.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>How does the Reximeter ensure fair pricing?</Accordion.Header>
                  <Accordion.Body>
                    <p className='accord-text'>The Reximeter provides a fixed rate based on distance, eliminating hidden charges and ensuring transparent, consistent pricing.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Is there any surge pricing?</Accordion.Header>
                  <Accordion.Body>
                    <p className='accord-text'>No, we do not have surge pricing. The fare remains fixed and consistent, calculated by the Reximeter.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Can I book a ride without the app?</Accordion.Header>
                  <Accordion.Body>
                    <p className='accord-text'>Yes, you can board any rexitaxi vehicle on the road or inform the driver of your location, and they’ll start the ride manually. You’ll still benefit from the fixed rates provided by the Reximeter.</p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div className='col-lg-5'>
              <div className='sec2'>
                <img className='banimg' src={process.env.PUBLIC_URL + '/assets/images/homepage/Online-ride.jpg'} />
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Faq