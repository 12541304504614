import React from 'react'
import './user-acc.css'

const User_account = () => {
    return (
        <>
            <section className='User_account_sec'>
                <div className='container'>
                    <img className='logo' src={process.env.PUBLIC_URL + '/assets/images/logos/rexilogo.png'} />
                    <h1>User Account</h1>

                    <div className='row'>
                        <div className='col-md-6 mx-auto'>
                            <a href="mailto:rexi@gmail.com?subject=Delete%20User%20Account&body=Please%20delete%20my%20user%20account." className='delete-btn btn'>
                                Delete User Account
                            </a>
                            {/* <button className='delete-btn btn'>Delete User Account</button> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default User_account