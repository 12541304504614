import "./banner/Banner.css";
import Banner from "./banner/Banner";
import Conveince from "./Conveince/Conveince";
import OurPresence from "./OurPresence/OurPresence";
import WhyChoose from "./WhyChoose/WhyChoose";
import HowWeWork from "./HowWeWork/HowWeWork";
import StopWaiting from "./StopWaiting/StopWaiting";
import Faq from "./faq/Faq";


function Home() {


  return (
    <>
      <Banner />
      <Conveince />
      <div id="section1">
        <OurPresence />
      </div>
      <div id="section2">
        <WhyChoose />
      </div>
      <HowWeWork />
      <StopWaiting />
      <Faq />
    </>
  );
}

export default Home;
