import React from 'react'
import { Container } from 'react-bootstrap'
import './PrivacyPolicy.css'

function TermsCondition_User() {
    return (
        <>
            <section className='privacy_section'>
                <Container>
                    <div className='privacy_main'>
                        <div className='title_div mb-4'>
                            <h2 className='semi_title'>TERMS & CONDITIONS</h2>
                        </div>
                        <div className='mb-5 pb-3'>
                            <p className='sub_title'>TERMS AND CONDITIONS FOR USERS</p>



                            <p className='sub_title'>1. 1.Acceptance of Terms</p>
                            <p className='text ms-3 pb-2'>By downloading and using the RexiTaxi app, you agree to these Terms and Conditions. RexiTaxi reserves the right to update or modify these terms at any time without prior notice.</p>


                            <p className='sub_title'>2. Account Creation</p>
                            <p className='text ms-3 pb-2'>Users must create an account to use the RexiTaxi app. You are responsible for maintaining the confidentiality of your account information. RexiTaxi reserves the right to terminate or suspend accounts for misuse or breach of these terms.</p>

                            <p className='sub_title'>3. Booking Rides</p>
                            <p className='text ms-3 pb-2'>Users may book rides through the app by selecting a nearby RexiTaxi vehicle or by scanning the Reximeter on the vehicle. The fare is calculated based on the distance traveled and displayed in real-time on the app.</p>


                            <p className='sub_title'>4. Payments</p>
                            <p className='text ms-3 pb-2'>Payment for rides can be made through the app or in cash to the driver.
                            No hidden charges or surge pricing will be applied. The fare shown on the app at the end of the ride is final and must be paid in full</p>


                            <p className='sub_title'>5. Cancellations</p>
                            <p className='text ms-3 pb-2'>Users can cancel their ride anytime before the ride starts without any charges.
                            Once the ride has begun, the user is liable to pay the fare for the distance traveled, even if the ride is terminated early.</p>

                            <p className='sub_title'>6. User Responsibilities</p>
                            <p className='text ms-3 pb-2'>Users must ensure that the information provided in their account is accurate and up to date.
                            Users must adhere to all applicable laws while using the RexiTaxi service and must treat drivers with respect.</p>

                            <p className='sub_title'>7. App Usage</p>
                            <p className='text ms-3 pb-2'>The RexiTaxi app is provided on an “as-is” basis. RexiTaxi does not guarantee uninterrupted or error-free service. Users must have a stable internet connection to book rides through the app.</p>

                            <p className='sub_title'>8. Limitation of Liability</p>
                            <p className='text ms-3 pb-2'>RexiTaxi is not responsible for any loss or damage incurred while using the app or the ride service.
                            RexiTaxi is not liable for delays, service interruptions, or issues caused by factors outside its control, including but not limited to traffic, weather conditions, or third-party actions.</p>

                            <p className='sub_title'>9. Data Privacy</p>
                            <p className='text ms-3 pb-2'>Your data is protected in accordance with our Privacy Policy. RexiTaxi does not share your data with any third parties without your explicit consent.</p>

                            <p className='sub_title'>10. Termination</p>
                            <p className='text ms-3 pb-2'>RexiTaxi reserves the right to terminate any user’s account or access to the service for breach of these terms or for inappropriate conduct.</p>

                            <p className='sub_title'>11. Dispute Resolution</p>
                            <p className='text ms-3 pb-2'>Any disputes related to the app or the service must be reported to RexiTaxi customer support. RexiTaxi will address the issue and may offer solutions such as refunds or service corrections.</p>



                        </div>

                    </div>
                </Container>
                <div className='privacy_overlay'></div>
            </section>
        </>
    )
}

export default TermsCondition_User