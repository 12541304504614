import React from "react";
import "./Banner.css";
import axios from "axios";
import { Link } from "react-router-dom";

function Banner() {
  return (
    <section className="home_banner_section mb-4">
      <div className="container-fluid p-0 ">
        <div className="allbancontent_div">
          <div className="bannerimg_div">
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/images/homepage/homebanner.png"
              }
              className="bannerimg"
            />
          </div>

          <div className="overlaytextdiv">
            <h1 className="welttt">Welcome to Rexi!</h1>
            <p className="subtexttitle">Scan to book or ride without the app.
              Fair rates with our Reximeter—no surge pricing.
              Spot a Rexi, hop in, and go!</p>
          </div>

          <div className="button_div">


            <Link to={"https://www.apple.com/in/app-store/"} target="blank">
              {" "}
              <button className="buttonclass me-2">
                {" "}
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/homepage/appstore.png"
                  }
                  className=" buttonimage"
                />
              </button>
            </Link>
            <Link to={"https://play.google.com/store/"} target="blank">
              {" "}
              <button className="buttonclass">
                {" "}
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/homepage/gplay.png"
                  }
                  className=" buttonimage"
                />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
