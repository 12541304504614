import React from 'react'
import { Container } from 'react-bootstrap'
import './PrivacyPolicy.css'

function TermsCondition_Rider() {
  return (
    <>
    <section className='privacy_section'>
        <Container>
            <div className='privacy_main'>
                <div className='title_div mb-4'>
                    <h2 className='semi_title'>TERMS & CONDITIONS</h2>
                </div>
                <div className='mb-5 pb-3'>
                    <p className='sub_title'>TERMS AND CONDITIONS FOR RIDERS</p>
                    
                </div>

                
                <div>
                    <p className='sub_title'>1. Acceptance of Terms</p>
                    <p className='text ms-3 pb-2'>By using the RexiTaxi service, you agree to these Terms and Conditions. RexiTaxi reserves the right to update or modify these terms at any time without prior notice.</p>




                    <p className='sub_title'>2. Booking Rides</p>
                    <p className='text ms-3 pb-2'>You may book a ride through the RexiTaxi app or by hailing a RexiTaxi vehicle directly.
                    The fare is calculated based on the Reximeter, and you agree to pay the fare at the end of the ride.</p>




                    <p className='sub_title'>3. Payment</p>
                    <p className='text ms-3 pb-2'>Payment can be made through cash or any other payment method provided by RexiTaxi. The fare displayed on the Reximeter at the end of the ride is final and must be paid in full. No hidden fees or surge pricing will be applied.</p>




                    <p className='sub_title'>4. Cancellation Policy</p>
                    <p className='text ms-3 pb-2'>You may cancel a ride before it begins without incurring any charges.
                    Once the ride has started, you are responsible for paying the fare up to the point of cancellation as calculated by the Reximeter.</p>



                    <p className='sub_title'>5.  Rider Conduct</p>
                    <p className='text ms-3 pb-2'>You agree to maintain appropriate behavior during the ride. Any inappropriate, illegal, or unsafe conduct may result in the termination of the ride without refund.
                    You are responsible for any damage caused to the vehicle during the ride.</p>



                    <p className='sub_title'>6. Liability</p>
                    <p className='text ms-3 pb-2'>RexiTaxi is not responsible for any personal belongings left in the vehicle.
                    RexiTaxi is not liable for any damages or injuries incurred during the ride due to unforeseen events or actions of the rider.</p>


                    <p className='sub_title'>7. Privacy</p>
                    <p className='text ms-3 pb-2'>Your personal information will be collected and used according to our Privacy Policy. RexiTaxi does not share your information with third parties without your consent.</p>



                    <p className='sub_title'>8. Dispute Resolution</p>
                    <p className='text ms-3 pb-2'>Any disputes or complaints about the service must be reported to RexiTaxi customer support within 24 hours. RexiTaxi will review the dispute and may take appropriate action, including refunds or corrective measures.</p>



                    
                    
                </div>
            </div>
        </Container>
        <div className='privacy_overlay'></div>
    </section>
</>
  )
}

export default TermsCondition_Rider