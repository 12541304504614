import React from "react";
import "./App.css";
import "./index.css";

import { Route, Routes } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import ContactUs from "./components/ContactUs/ContactUs";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Aboutus from "./components/Aboutus/Aboutus";
import TermsCondition_User from "./components/PrivacyPolicy/TermsCondition_User";
import TermsCondition_Rider from "./components/PrivacyPolicy/TermsCondition_Rider";
import ScrollToTop from "./components/ScrollTotop/ScrollTotop";
import User_account from "./components/user-account/User_account";

function App() {
  // const scrollToSection = (sectionId) => {
  //   const section = document.getElementById(sectionId);
  //   console.log(section);
  //   if (section) {
  //     section.scrollIntoView({ behavior: "smooth", block: "start" });
  //   }
  // };
  return (
    <>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route
          path="/terms&conditions-user"
          element={<TermsCondition_User />}
        />
        <Route
          path="/terms&conditions-rider"
          element={<TermsCondition_Rider />}
        />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/user-account" element={<User_account />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
