import React from 'react'
import TravellerMost from './TravellerMost/TravellerMost'
import YouNeed from './YouNeed/YouNeed'
import OurVision from './OurVision/OurVision'
import Conuter from './Conuter/Conuter'
import StopWaiting from '../home/StopWaiting/StopWaiting'
import './Aboutus.css'

function Aboutus() {
  return (
    <>
    
    <TravellerMost/>
    <YouNeed/>
    <OurVision/>
    <Conuter/>
    <StopWaiting csss="backoverlay"/>
    </>
  )
}

export default Aboutus