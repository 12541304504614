import React from 'react'
import './OurVision.css'
import { Col, Container, Row } from 'react-bootstrap'
function OurVision() {
  return (
    <>
    <section className='ourvision_section'>
        <Container>
            <div className='ourvision_main'>
                <Row>
                    <Col xxl={6} xl={6} lg={6} md={6}  className='mb-3'>
                    <div className='ourcardd_div'>
                        <div className='d-flex '>
                        <img src={process.env.PUBLIC_URL + "/assets/images/Aboutus/sqqqrr.png"} className="sqrimg" />
                        
                        <p className='sub_title'>OUR VISION</p>
                        </div>
                        <p className='text'>"We aim to create a world where people forget about waiting to book rides. With RexiTaxi, customers can simply hop into a vehicle, enjoy transparent fares, and travel without the hassle—making transportation easier and fairer for everyone."</p>

                    </div>
                    </Col>
                    <Col xxl={6} xl={6} lg={6} md={6}  className='mb-3'>
                    <div className='ourcardd_div'>
                    <div className='d-flex'>
                    <img src={process.env.PUBLIC_URL + "/assets/images/Aboutus/sqqqrr.png"} className="sqrimg" />
                    <p className='sub_title'>OUR MISSION</p>
                    </div>
                    <p className='text'>"To provide safe, efficient, and affordable transportation that exceeds customer expectations. At RexiTaxi, we are committed to delivering the highest level of service with every ride, ensuring that customers enjoy convenience, trust, and satisfaction."</p>
                    </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </section>
    </>
  )
}

export default OurVision