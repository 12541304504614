import React from 'react'
import './Conuter.css'
import { Col, Container, Row } from 'react-bootstrap'
import { faCity, faCloudArrowDown, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
function Conuter() {
  return (
    <>
      <section className='counter_section'>
        <Container>
          <div className='counter_main_div'>
            <div className='topsct_div'>
              <img src={process.env.PUBLIC_URL + "/assets/images/Aboutus/Abou.png"} className="howwweimg" />
            </div>
            <div className='conterrmainnnn'>
              <Row>
                <Col xxl={6} xl={7} lg={9} md={10} sm={11} className='mx-auto'>
                  <div className='contdivv'>
                    <div className='subconttdiv'>
                      <div>
                        <div className='radiusdiv mb-2'>
                          <div className='unerradiusdiv'>
                            <FontAwesomeIcon icon={faCloudArrowDown} />
                          </div>
                        </div>
                        <p className='text text-center'>15 M <br /> Downloads</p>
                      </div>
                      <div>
                        <div className='radiusdiv mb-2'>
                          <div className='unerradiusdiv'>
                            <FontAwesomeIcon icon={faCity} />
                          </div>
                        </div>
                        <p className='text text-center'>100+ <br /> Cities</p>
                      </div>
                      <div>
                        <div className='radiusdiv mb-2'>
                          <div className='unerradiusdiv'>
                            <FontAwesomeIcon icon={faUser} />
                          </div>
                        </div>
                        <p className='text text-center'>900+ <br /> Riders</p>
                      </div>
                      <div>
                        <div className='radiusdiv mb-2'>
                          <div className='unerradiusdiv'>
                            <FontAwesomeIcon icon={faCloudArrowDown} />
                          </div>
                        </div>
                        <p className='text text-center'>20 M <br /> Custmers</p>
                      </div>
                    </div>
                  </div>
                  <div className='stripdiv'>
                    <img src={process.env.PUBLIC_URL + "/assets/images/homepage/strip.png"} className="stripimg" />
                  </div>

                </Col>
              </Row>

              <div className='backgrounddiv'>
              </div>
            </div>

          </div>
        </Container>
      </section>

    </>
  )
}

export default Conuter