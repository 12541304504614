import React from 'react'
import { Container } from 'react-bootstrap'
import './PrivacyPolicy.css'

function PrivacyPolicy() {
    return (
        <>
            <section className='privacy_section'>
                <Container>
                    <div className='privacy_main'>
                        <div className='title_div mb-4'>
                            <h2 className='title'>Privacy Policy</h2>
                        </div>
                        <div className='mb-5 pb-3'>
                            <p className='sub_title'>Our Commitment To You</p>
                            <p className='privcytxt'>At OTOWALA, we prioritize your privacy above all else. Our commitment to safeguarding your personal information is reflected in every aspect of our service, so you can trust us to provide a secure and comfortable ride.</p>
                        </div>
                        <div>
                            <p className='sub_title'>1. USER ACCEPTANCE</p>
                            <p className='text ms-3'>By accessing or using the OTOWALA Platform or the Services, you agree and consent to this Policy, along with any amendments made by the Company at its sole discretion and posted on the OTOWALA Platform from time to time. Any collection, processing, retrieval, transfer, use, storage, disclosure, and protection of your Information will be in accordance with this Policy and applicable laws including but not limited to the Information Technology Act, 2000, and the rules framed thereunder (“Applicable Laws”). If you do not agree with the Policy, please do not use or access the OTOWALA Platform.</p>
                            <p className='text ms-5'>You hereby represent to OTOWALA that:</p>
                           
                            <div className='d-flex ms-5'>
                                <p className='text me-2'>a.) </p>
                                <p className='text'>You confirm that any Information you provide to OTOWALA is accurate, up-to-date, and complete, and that you have the necessary rights and permissions to share this Information with OTOWALA.</p>
                            </div>
                            <div className='d-flex ms-5'>
                                <p className='text me-2'>b.) </p>
                                <p className='text'>You confirm that any Information you provide to OTOWALA is accurate, up-to-date, and complete, and that you have the necessary rights and permissions to share this Information with OTOWALA.</p>
                            </div>
                            <div className='d-flex ms-5 mb-4'>
                                <p className='text me-2'>c.) </p>
                                <p className='text'>You confirm that any Information you provide to OTOWALA is accurate, up-to-date, and complete, and that you have the necessary rights and permissions to share this Information with OTOWALA.</p>
                            </div>

                            <div className='mb-5'>
                                <p className='sub_title'>2. DEFINITIONS</p>
                                <p className='text  ms-3'>lorem ipsum is simply dummy text of the printing and typesetting industry. lorem ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type & scrambled it to make a type specimen book. it has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. it was popularised in the 1960s with the release of letraset sheets containing lorem ipsum passages, and more recently with desktop publishing software like aldus page- maker including versions of lorem ipsum.</p>
                            </div>
                            <div>
                                <p className='sub_title'>3. WHAT INFORMATION DO WE COLLECT?</p>
                                <p className='text  ms-3  mb-4'>lorem ipsum is simply dummy text of the printing and typesetting industry. lorem ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type & scrambled it to make a type specimen book. it has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. it was popularised in the 1960s with the release of letraset sheets containing lorem ipsum passages, and more recently with desktop publishing software like aldus page- maker including versions of lorem ipsum.</p>

                                <p className='sub_title  ms-3'>i. INFORMATION YOU PROVIDE TO US</p>
                                <p className='text  ms-3 mb-4'>lorem ipsum is simply dummy text of the printing and typesetting industry. lorem ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type & scrambled it to make a type specimen book. it has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. it was popularised in the 1960s with the release of letraset sheets containing lorem ipsum passages, and more recently with desktop publishing software like aldus page- maker including versions of lorem ipsum.</p>


                                <p className='sub_title  ms-3'>ii. INFORMATION WE COLLECT AS YOU ACCESS AND USE RAPIDO APP</p>
                                <p className='text  ms-3'>lorem ipsum is simply dummy text of the printing and typesetting industry. lorem ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type & scrambled it to make a type specimen book. it has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. it was popularised in the 1960s with the release of letraset sheets containing lorem ipsum passages, and more recently with desktop publishing software like aldus page- maker including versions of lorem ipsum.</p></div>


                        </div>
                    </div>
                </Container>
                <div className='privacy_overlay'></div>
            </section>
        </>
    )
}

export default PrivacyPolicy