import React from 'react'
import './YouNeed.css'
import { Col, Container, Row } from 'react-bootstrap'
function YouNeed() {
    return (
        <>
            <section className='youneed_section'>
                <Container>
                    <div className='youneed_div'>

                        <Row>
                            <Col xxl={6} xl={6} lg={6}  >
                                <div className='youneed_text'>
                                    <p className='text motto'>OUR MOTTO</p>
                                    <h2 className='title'>Wherever You Need To Go We'll Take You There.</h2>
                                    <p className='text'>"Whether it's a quick trip across town or a special event, RexiTaxi ensures you get there safely, comfortably, and on time. Our goal is to provide reliable rides whenever and wherever you need them."</p>
                                </div>
                            </Col>
                            <Col xxl={6} xl={6} lg={6} >
                                <div className='youneeedimgdiv'>
                                    <div className='youneedmain_imgdiv'>
                                        <img src={process.env.PUBLIC_URL + "/assets/images/Aboutus/onlinetaxi.png"} className="onlietaximg" />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </Container>
            </section>
        </>
    )
}

export default YouNeed