import React from 'react'
import './TravellerMost.css'
import { Col, Container, Row } from 'react-bootstrap'
function TravellerMost() {
    return (
        <>
            <section className='travell_section'>
                <Container>
                    <div className='travell_main'>
                        <Row>
                            <Col xxl={6} xl={6} lg={6} className='my-auto'>
                                <div className='travvell_div'>
                                    <h2 className='title'>Travellers most <br /> Trusted Choice</h2>
                                    <p className='text'>"RexiTaxi has earned the trust of thousands of riders by providing reliable, transparent, and fair rides. With no hidden charges and a simple booking process, we ensure that every trip is smooth and worry-free. Join the many satisfied customers who choose RexiTaxi for all their travel needs."</p>
                                </div>
                            </Col>
                            <Col xxl={6} xl={6} lg={6} >
                                <div className=''>

                                    <Row>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} >
                                            <div className='leftcoll'>
                                                <div className='leftimgdiv'>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/Aboutus/mobilegirl.png"} className="leftimg" />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6} className='ps-0'>
                                            <div className=''>
                                                <div className='rightimgdiv'>
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/Aboutus/callingboy.png"} className="rightimg" />
                                                </div>
                                                <div className='conenve_blanks_div'>

                                                </div>

                                            </div>
                                        </Col>
                                    </Row>

                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default TravellerMost