import React from 'react'
import './OurPresence.css'
import { Col, Container, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCity, faCloudArrowDown, faUser } from '@fortawesome/free-solid-svg-icons'
function OurPresence() {
    return (
        <>
            <section className='ourpresence_section'>
                <Container>
                    <div className='ourpresence_divmain'>
                        <h2 className='title text-center'>Our Features</h2>

                        <Row className='mt-5'>
                            <Col xxl={6} xl={6} lg={6} md={12} sm={12} className='my-auto'>

                                {/* <p className='text text-center'>Our presence has revolutionized intra-city travel, making it both affordable and efficient for everyone to achieve seamless last-mile connectivity.</p> */}

                                <div className='present_imagediv mb-3'>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/homepage/mobilemockups.png"} className="presenttimg" />
                                </div>

                            </Col>


                            <Col xxl={6} xl={6} lg={6} md={12} sm={12} className=''>
                                <div className='ouefetdesccc'>
                                    <div className='d-flex mb-3'>
                                        <div className='iconsec'>
                                            <FontAwesomeIcon className='check' icon={faCircleCheck} />
                                        </div>
                                        <div className='cont-secc'>
                                            <p className='textt'><strong>Reximeter : </strong>Our unique Reximeter ensures you pay a fixed rate based on the distance traveled. It starts automatically when your ride begins, providing real-time fare updates directly on the app. The Reximeter guarantees transparent pricing with no hidden charges, ensuring you always know what you’ll pay.</p>
                                        </div>
                                    </div>
                                    <div className='d-flex mb-3'>
                                        <div className='iconsec'>
                                            <FontAwesomeIcon className='check' icon={faCircleCheck} />
                                        </div>
                                        <div className='cont-secc'>
                                            <p className='textt'><strong>Fixed Rate Based on Distance : </strong>The fare is determined by the distance traveled, as calculated by the Reximeter. Enjoy a fair and consistent rate every time, without any surprises. </p>
                                        </div>
                                    </div>

                                    <div className='d-flex mb-3'>
                                        <div className='iconsec'>
                                            <FontAwesomeIcon className='check' icon={faCircleCheck} />
                                        </div>
                                        <div className='cont-secc'>
                                            <p className='textt'><strong>No Surge Pricing : </strong>Experience consistent pricing with no surge charges. Our rates remain steady, ensuring you never pay more than expected.</p>
                                        </div>
                                    </div>

                                    <div className='d-flex mb-3'>
                                        <div className='iconsec'>
                                            <FontAwesomeIcon className='check' icon={faCircleCheck} />
                                        </div>
                                        <div className='cont-secc'>
                                            <p className='textt'><strong>Easy Booking Without the App : </strong>Prefer not to use the app? Simply board any rexitaxi vehicle you see on the road or inform the driver of your location, and they’ll start the ride manually. You’ll still benefit from the same fixed rates calculated by the Reximeter.</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>

                        <div className='row'>
                            <div className='col-md-8 mx-auto'>
                                <div className='contdivv'>
                                    <div className='subconttdiv'>
                                        <div>
                                            <div className='radiusdiv mb-2'>
                                                <div className='unerradiusdiv'>
                                                    <FontAwesomeIcon icon={faCloudArrowDown} />
                                                </div>
                                            </div>
                                            <p className='text text-center'>15 M <br /> Downloads</p>
                                        </div>
                                        <div>
                                            <div className='radiusdiv mb-2'>
                                                <div className='unerradiusdiv'>
                                                    <FontAwesomeIcon icon={faCity} />
                                                </div>
                                            </div>
                                            <p className='text text-center'>100+ <br /> Cities</p>
                                        </div>
                                        <div>
                                            <div className='radiusdiv mb-2'>
                                                <div className='unerradiusdiv'>
                                                    <FontAwesomeIcon icon={faUser} />
                                                </div>
                                            </div>
                                            <p className='text text-center'>900+ <br /> Riders</p>
                                        </div>
                                        <div>
                                            <div className='radiusdiv mb-2'>
                                                <div className='unerradiusdiv'>
                                                    <FontAwesomeIcon icon={faCloudArrowDown} />
                                                </div>
                                            </div>
                                            <p className='text text-center'>20 M <br /> Custmers</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='stripdiv'>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/homepage/strip.png"} className="stripimg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>

                <div className='presentbacimg_div'>
                    <img src={process.env.PUBLIC_URL + "/assets/images/homepage/OurPresence_background.png"} className="presentbackimg" />
                </div>
            </section>
        </>
    )
}

export default OurPresence